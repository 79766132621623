<template>
    <vs-row vs-justify="center">
        <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-xs="12">
            <vs-card>
                <vs-row vs-justify="center">
                    <vs-col vs-lg="6">
                        <h3>Noticias</h3>
                    </vs-col>
                    <vs-col vs-offset="5" vs-lg="1" vs-align="flex-end">
                        <vs-button @click="nuevo" color="success" type="relief">Agregar Nuevo</vs-button>
                    </vs-col>
                </vs-row>
            </vs-card>

            <vs-card>
                <vs-row vs-justify="center">
                    <vs-col vs-lg="12">
                        
                            <div slot="header">
                                <div class="d-flex align-items-center">
                                <div>
                                    <h5 class="card-title">Noticias</h5>
                                    <h6 class="card-subtitle mb-0">Todos las Noticias</h6>
                                </div>
                                </div>
                            </div>
                            <VityTable 
                                v-if="url"
                                :columns="columns"
                                :url="url+'notices'"
                                :params="{admin: 1}"
                                :orderBy="{
                                    column: 0,
                                    type: 1
                                }"
                            />
                    
                    </vs-col>
                </vs-row>
            </vs-card>

            <vs-card v-if="noticia">
                <h4 class="card-title d-flex">
                    Noticia {{noticia.title}}
                </h4>
                <p class="card-subtitle">
                    Aqui podras cambiar toda la informacion de la Noticia
                </p>
                
                <vs-row vs-justify="center">
                    <vs-col vs-lg="2" vs-xs="2">
                        <label for="" class="mr-2 mb-0">Activo</label>
                        <vs-switch color="success" v-model="noticia.active"/>
                    </vs-col>
                    <vs-col vs-lg="4" vs-xs="12">
                        <vs-input maxlength="255" label="Titulo" class="inputx" placeholder="Titulo" v-model="noticia.title"/>
                    </vs-col>
                    <vs-col vs-lg="4" vs-xs="12">
                        <vs-input maxlength="255" label="url" class="inputx" placeholder="url" v-model="noticia.url"/>
                    </vs-col>
                    <vs-col vs-lg="12" vs-xs="12">
                        <br>
                        <label>Descripcion</label>
                        <vs-textarea rows="3" counter="255" label="Counter: 255" :counter-danger.sync="descripcionDanger" v-model="noticia.shortdesc" />
                    </vs-col>
                    <vs-col vs-lg="4" vs-xs="12">
                        <input type="file" @change="cambiarFoto" />
                    </vs-col>
                    <vs-col vs-lg="4" vs-xs="12" style="max-height: 198px">
                        <img style="display: block;min-width: 100%;width: 100%;min-height: 100%;height: 100%;" v-show="noticia.img" :src="noticia.img"/>
                    </vs-col>
                    <vs-col vs-lg="4" vs-xs="12">
                        <vue-tags-input placeholder="Añadir..." :separators="[',']" v-model="tag" :tags="tags" @tags-changed="newTags => tags = newTags" />
                    </vs-col>
                    <vs-col vs-lg="4" vs-xs="12">
                        <vs-select autocomplete multiple class="selectExample" label="Categorias" v-model="noticia.categories">
                            <vs-select-item :key="index" :value="item.id" :text="item.title" v-for="(item,index) in categorias" />
                        </vs-select>
                    </vs-col>
                    <vs-col vs-lg="4" vs-xs="12">
                        <vs-input label="Creado el" class="inputx" placeholder="Creado el" :value="$parent.$parent.formatDate('d/m/Y H:i:s', noticia.created_at, true)" disabled/>
                    </vs-col>
                    <vs-col vs-lg="4" vs-xs="12">
                        <vs-input label="Actualizado el" class="inputx" placeholder="Actualizado el" :value="$parent.$parent.formatDate('d/m/Y H:i:s', noticia.updated_at, true)" disabled/>
                    </vs-col>
                    <vs-col vs-lg="4" vs-xs="12">
                        <label>Activado el</label><br>
                        <v-date-picker v-model="noticia.actived_at" mode="dateTime" is24hr>
                            <template v-slot="{ inputValue, inputEvents }">
                                <input
                                class="px-2 py-1 border focus:outline-none focus:border-blue-300"
                                :value="inputValue"
                                v-on="inputEvents"
                                />
                            </template>
                        </v-date-picker>
                    </vs-col>
                    <br>
                    <vs-col vs-lg="12" vs-xs="12">
                        <vs-button @click="save" color="success" type="relief">Guardar</vs-button>
                    </vs-col>
                </vs-row>
            </vs-card>

            <vs-card v-if="noticia">
                <h4 class="card-title d-flex">
                    Contenido
                </h4>
                <vs-row vs-justify="center">
                    <vs-col vs-lg="12" vs-xs="12">
                        <vue-editor v-model="noticia.body" />
                    </vs-col>
                </vs-row>
            </vs-card>
        </vs-col>
    </vs-row>
</template>

<script>
import axios from 'axios'
import VityTable from "@/components/VityTable.vue";
import {VueEditor} from 'vue2-editor'
import { VueTagsInput, createTag, createTags } from '@johmun/vue-tags-input';

export default {
    name: 'Noticias',
    components: {
        VityTable,
        VueEditor,
        VueTagsInput,
    },
    data: function(){
        return{
            columns:[
                {
                    name: 'ID',
                    dbName: 'id',
                    orderable: true,
                    searchable: true
                },
                {
                    name: 'Titulo',
                    dbName: 'title',
                    orderable: true,
                    searchable: true
                },
                {
                    name: 'Accion',
                    dbName: '',
                    orderable: false,
                    searchable: false,
                    template: '<a href="/noticias/TPLData">EDITAR</a>',
                    reference: 'url'
                },
            ],
            url: process.env.VUE_APP_URL,
            noticia: null,
            descripcionDanger: false,
            required: [
                {
                    name: 'title',
                    viewName: 'Titulo'
                },
                {
                    name: 'url',
                    viewName: 'Url'
                },
                {
                    name: 'shortdesc',
                    viewName: 'Descripcion'
                },
                {
                    name: 'body',
                    viewName: 'Noticia'
                },
            ],
            nuevaImagen: null,
            tag: '',
            tags: [],
            categorias: []
        } 
    },
    methods:{
        getNoticia: function(id){
            if(!id)
                this.participant = null;
            else{
                axios
                    .get(process.env.VUE_APP_URL+'notices/'+id)
                    .then(response => {
                        let rs = response.data;
                        if(rs.state == 'OK'){
                            this.noticia = rs.data;
                            this.noticia.active = this.noticia.active ? true : false;
                            if(this.noticia.keywords && this.noticia.keywords.length > 0)
                                this.tags = createTags(this.noticia.keywords.split(','));
                            var cat = [];
                            this.noticia.categories.forEach(function(x){
                                cat.push(x.id);
                            });
                            this.noticia.categories = cat;
                        }
                        else{
                            this.noticia = null;
                            this.$parent.$parent.errors = ['Participante no encontrado'];
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        this.$parent.$parent.errors = ['Fallo al conectar'];
                    });
            }
        },
        getCategorias: function(id){
            axios
                .get(process.env.VUE_APP_URL+'notice_categories')
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK')
                        this.categorias = rs.data;
                })
        },
        save: function(){
            if(this.$parent.$parent.checkParams(this.required, this.noticia))
                return;

            var params = new FormData();
            params.append('active', this.noticia.active ? 1 : 0);
            params.append('title', this.noticia.title);
            params.append('url', this.noticia.url);
            params.append('shortdesc', this.noticia.shortdesc);
            params.append('body', this.noticia.body);
            if(this.noticia.actived_at)
                params.append('actived_at', this.$parent.$parent.formatDate('Y-m-d H:i:s', this.noticia.actived_at));
            if(this.nuevaImagen)
                params.append('img', this.nuevaImagen);
            if(this.noticia.keywords)
                params.append('keywords', this.noticia.keywords);
            if(this.noticia.categories)
                params.append('categories', this.noticia.categories.join());

            if(this.noticia.id){
                axios
                    .post(process.env.VUE_APP_URL+'notices/'+this.noticia.id, params)
                    .then(response => {
                        let rs = response.data;
                        if(rs.state == 'OK'){
                            this.$router.push('/noticias/'+rs.data);
                            //this.$router.push('participantes/'+rs.data);
                            this.$parent.$parent.aciertos = ['Guardado correctamente'];
                        }
                        else
                            this.$parent.$parent.errors = [rs.msg];
                    })
                    .catch(error => {
                        this.$parent.$parent.errors = ['Hubo un problema al conectar'];
                    });
            }
            else{
                axios
                    .post(process.env.VUE_APP_URL+'notices', params)
                    .then(response => {
                        let rs = response.data;
                        if(rs.state == 'OK')
                            this.$router.push('/noticias/'+rs.data);
                        else
                            this.$parent.$parent.errors = [rs.msg];
                    })
                    .catch(error => {
                        this.$parent.$parent.errors = ['Hubo un problema al conectar'];
                    });
            }
        },
        nuevo: function(){
            this.$router.push('/noticias/new');
            this.tags = [];
        },
        cambiarFoto: function(data){
            this.nuevaImagen = data.target.files[0];
        },
    },
    watch: {
        getTitle: function(newval, oldval){
            if(newval == null) return;
                this.noticia.url = this.$parent.$parent.makeUrl(this.noticia.title);
        },
        tags: function(newval, oldval){
            var result = [];
            newval.forEach(tag => {
                result.push(tag.text);
            });
            this.noticia.keywords = result.join(',');
        }
    },
    computed: {
        getTitle: function(){
            if(this.noticia)
                return this.noticia.title;
            return null;
        }
    },
    created: function(){
        if(this.$route.params.id == 'new')
            this.noticia = {};
        else
            this.getNoticia(this.$route.params.id);
        this.getCategorias();
    },
	beforeRouteUpdate (to, from, next) {
        if(to.params.id == 'new')
            this.noticia = {};
        else
            this.getNoticia(to.params.id);
		next();
    },
}
</script>